<template>
  <div class="absolute-position">
    <!-- Input file -->
    <input
      id="text-reader"
      class="d-none"
      type="file"
      @change="loadTextFromFile"
      accept=".csv"
    />
    <v-overlay :value="alert_error">
      <v-alert
        v-model="alert_error"
        type="error"
        border="left"
        close-text="Close Error"
        max-width="600"
        elevation="2"
        dismissible
        class="multi-line"
        transition="scale-transition"
      >
        <span v-html="content"></span>
      </v-alert>
    </v-overlay>
    <v-overlay :value="alert_load">
      <v-alert
        v-model="alert_load"
        type="info"
        border="left"
        max-width="600"
        elevation="2"
        class="multi-line"
      >
        Il piano è in fase di elaborazione, attendere. L'operazione potrebbe
        richiedere qualche minuto.
        <br /><br />
        <v-progress-linear
          indeterminate
          color="yellow darken-2"
        ></v-progress-linear>
      </v-alert>
    </v-overlay>
  </div>
</template>

<script>
import papa from "papaparse";
export default {
  name: "TextReader",
  props: {
    disableAlert: { default: false, type: Boolean }, //If true reset all Alerts to disappear
  },
  data() {
    return {
      file: null,
      content: null,
      alert_error: false,
      alert_load: false,
      alert_error_text:
        "Il file caricato non sembra un file .CSV.<br/>Caricare un file corretto.<br/>Grazie.",
      papaconfig: {
        delimiter: "", // auto-detect
        newline: "", // auto-detect
        quoteChar: '"',
        escapeChar: '"',
        header: true,
        transformHeader: function (header, index) {
          let newHeader = header
            .replace("Nodi impattati - analisi ", "")
            .toLowerCase();
          if (index === 3) newHeader = "probImpatto";
          return newHeader;
        },
        dynamicTyping: true,
        encoding: "",
        comments: false,
        complete: function (results, file) {
          console.log("Parsing complete:", results, file);
        },
        error: function (error, file) {
          console.log("Parsing error:", error, file);
        },
        withCredentials: undefined,
        transform: undefined,
        delimitersToGuess: [",", "\t", "|", ";"],
      },
    };
  },
  watch: {
    content: function (value) {
      return value;
    },
    disableAlert: function (value) {
      if (value) {
        this.alert_error = false;
        this.alert_load = false;
      }
    },
  },
  methods: {
    loadTextFromFile(ev) {
      this.file = ev.target.files[0];
      const reader = new FileReader();

      if (this.file.name.endsWith(".csv")) {
        reader.onload = (res) => {
          this.content = this.changeResult(
            papa.parse(res.target.result, this.papaconfig)
          );
          this.$emit("load", this.content);
          this.alert_load = true;
        };
        reader.onerror = (err) => console.log(err);
        reader.readAsText(this.file);
      } else {
        this.content = this.alert_error_text;
        this.alert_error = true;
        reader.onerror = (err) => console.log(err);
        reader.readAsText(this.file);
      }
    },
    changeResult(results) {
      let newResult = {
        empty: false,
        fileError: false,
        nodesAnalisiSistemistica: [],
        nodesAnalisiMonitoraggio: [],
        bsImpact: [],
        count: 0,
      };
      if (results.data.length < 1) {
        newResult.empty = true;
      }
      results.data.forEach((el) => {
        if (
          Object.keys(el).length == 1 &&
          el.sistemistica == null &&
          newResult.fileError != true
        ) {
          newResult.fileError = false;
        } else if (Object.keys(el).length != 4) {
          newResult.fileError = true;
        }

        if (el.sistemistica != null)
          newResult.nodesAnalisiSistemistica.push(el.sistemistica);
        if (el.monitoraggio != null)
          newResult.nodesAnalisiMonitoraggio.push(el.monitoraggio);
        if (el.bs != null)
          newResult.bsImpact.push({
            bs: el.bs,
            probabilita: el.probImpatto === null ? "" : el.probImpatto,
          });
        newResult.count++;
      });
      return newResult;
    },
  },
};
</script>

<style scoped>
.absolute-position {
  position: absolute;
  width: 100%;
  z-index: 2;
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column-reverse;
}
.absolute-position input#text-reader {
  opacity: 0;
  margin-top: -100px;
}
.multi-line {
  white-space: pre-line;
}
</style>
